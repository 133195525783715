<template>
    <div class="animated fadeIn">
        <CRow>
            <CCol lg="12">

                <CButton @click="$router.go(-1)" size="sm" color="warning">
                    <font-awesome-icon icon="arrow-left" /> Go back
                </CButton>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="12">
                <CAlert show color="primary">
                    <h4 class="alert-heading"><font-awesome-icon icon="share-from-square" /> {{ $route.query.name }}
                    </h4>
                    <hr>
                    <p class="mb-0">
                        Has {{ document_template_shared.length }} shared document template/s.
                    </p>
                </CAlert>
            </CCol>
        </CRow>

        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader>
                        <font-awesome-icon icon="share-from-square" /> Shared Document Template/s
                    </CCardHeader>
                    <CCardBody>



                        <CTabs :active-tab="active_tab" @update:activeTab="changeTab">
                            <CTab>
                                <template slot="title">
                                    Active Document Template/s Shared <CBadge color="success">( {{ [...new
                                        Set(document_template_shared.map((item) => item.name))].length }} )</CBadge>
                                    <a-popover title="NOTE:" placement="right" style="padding-left: 4px;">
                                        <template slot="content">
                                            <template>
                                                <i>This indicates that the document template/s shared to this user are
                                                    active.</i> <br>
                                            </template>
                                        </template>
                                        <font-awesome-icon style="color: #468dc8;" icon="question-circle" />
                                    </a-popover>
                                </template>

                                <CRow>
                                    <CCol lg="12">
                                        <div style="display: flex;" class="search-container">
                                            <span style="font-size: 14px; padding-top: 5px; padding-right: 5px;">Search:
                                            </span>
                                            <input type="text" id="search" :class="$store.getters.getDarkMode ? 'dark-theme search-box' :'search-box'"
                                                placeholder="Enter text here." v-model="searchTerm">
                                        </div>
                                        <CDataTable :items="filteredItems" :fields="templates_field"
                                            :items-per-page="10" border @row-clicked="rowClicked" sorter pagination
                                            :loading="this.table_loading">
                                            <template slot="no-items-view" v-if="this.table_loading">
                                                <center>
                                                    <CSpinner style="width:4rem;height:4rem;" color="info" />
                                                </center>
                                            </template>

                                            <template #checkbox-header >
                                                <center>
                                                    <p-check class="p-icon p-bigger" color="success"
                                                        v-model="isCheckedAll" @change="selectChange($event)">
                                                        <font-awesome-icon class="icon" icon="check" />
                                                    </p-check>
                                                </center>
                                            </template>

                                            <template #checkbox="{ item }" >
                                                <td>
                                                    <center>
                                                        <p-check class="p-icon p-bigger" color="success" :value="item"
                                                            v-model="selected_templates">
                                                            <font-awesome-icon class="icon" icon="check" />
                                                        </p-check>
                                                        &nbsp;
                                                    </center>
                                                </td>
                                            </template>

                                        </CDataTable>
                                    </CCol>
                                </CRow>
                                <CRow v-if="!table_loading">
                                    <CCol lg="3">
                                        <CButton block size="sm" color="danger" @click=" removeUser()"
                                        v-if="$store.getters['can']('replace-document-template-shared')"
                                            :disabled="action_control">
                                            <font-awesome-icon icon="times" /> Remove User
                                        </CButton>
                                    </CCol>
                                    <CCol lg="3">
                                        <CButton block size="sm" color="warning" @click="showUserSelectableModal()"
                                        v-if="$store.getters['can']('remove-document-template-shared')"
                                            :disabled="action_control">
                                            <font-awesome-icon icon="user-slash" /> Replace User
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab title="Inactive">
                                <template slot="title">
                                    Inactive Document Template/s <CBadge color="danger">( {{ [...new
                                        Set(document_template_shared_inactive.map((item) => item.name))].length }} )
                                    </CBadge>
                                    <a-popover title="NOTE:" placement="right" style="padding-left: 4px;">
                                        <template slot="content">
                                            <template>
                                                <i>This indicates that the document template/s shared to this user are
                                                    currently not active.</i> <br>
                                            </template>
                                        </template>
                                        <font-awesome-icon style="color: #468dc8;" icon="question-circle" />
                                    </a-popover>
                                </template>
                                <CRow>
                                    <CCol lg="12">
                                        <div style="display: flex;" class="search-container">
                                            <span style="font-size: 14px; padding-top: 5px; padding-right: 5px;">Search:
                                            </span>
                                            <input type="text" id="search" :class="$store.getters.getDarkMode ? 'dark-theme search-box' :'search-box'"
                                                placeholder="Enter text here." v-model="searchTermInactive">
                                        </div>
                                        <CDataTable :items="filteredItemsInactive" :fields="templates_field"
                                            :items-per-page="10" border @row-clicked="rowClickedInactive" sorter
                                            pagination :loading="this.table_loading">
                                            <template slot="no-items-view" v-if="this.table_loading">
                                                <center>
                                                    <CSpinner style="width:4rem;height:4rem;" color="info" />
                                                </center>
                                            </template>

                                            <template #checkbox-header>
                                                <center>
                                                    <p-check class="p-icon p-bigger" color="success"
                                                        v-model="isCheckedAllInactive"
                                                        @change="selectChangeInactive($event)">
                                                        <font-awesome-icon class="icon" icon="check" />
                                                    </p-check>
                                                </center>
                                            </template>

                                            <template #checkbox="{ item }">
                                                <td>
                                                    <center>
                                                        <p-check class="p-icon p-bigger" color="success" :value="item"
                                                            v-model="selected_templates">
                                                            <font-awesome-icon class="icon" icon="check" />
                                                        </p-check>
                                                        &nbsp;
                                                    </center>
                                                </td>
                                            </template>

                                        </CDataTable>
                                    </CCol>
                                </CRow>
                                <CRow v-if="!table_loading">
                                    <CCol lg="3">
                                        <CButton block size="sm" color="danger" @click=" removeUser()"
                                        v-if="$store.getters['can']('remove-document-template-shared')"
                                            :disabled="action_control">
                                            <font-awesome-icon icon="times" /> Remove User
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CTab>
                        </CTabs>
                    </CCardBody>


                </CCard>
            </CCol>
        </CRow>

        <CRow>
            <CCol lg="12">
                <template>
                    <CCollapse :show="collapse">
                        <div>
                            <CCard>
                                <CCardHeader @click="innerCollapse = !innerCollapse">
                                    <label style="padding-top: 5px;"> <font-awesome-icon icon="share-from-square" /> Selected Document Template/s</label>
                                </CCardHeader>
                                <CCollapse :show="innerCollapse" class="mt-2">
                                    <CCardBody>

                                        <CDataTable :items="selected_templates.length > 0 ? selected_templates : []"
                                            :fields="collapse_fields"
                                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:' }"
                                            :items-per-page="10" items-per-page-select border sorter pagination
                                            :loading="table_loading" size="sm">
                                            <template slot="no-items-view" v-if="this.table_loading">
                                                <center>
                                                    <CSpinner style="width:4rem;height:4rem;" color="info" />
                                                </center>
                                            </template>

                                        </CDataTable>
                                    </CCardBody>
                                </CCollapse>
                            </CCard>
                        </div>
                    </CCollapse>
                </template>
            </CCol>
        </CRow>
        <UsersSelectableModal ref="usersSelectableModal" @selectedUser="userMultipleSelected($event)" />

    </div>

</template>

<script>
import UsersSelectableModal from '../../modals/UsersSelectableModal'
export default {
    name: "DocumentTemplateSharedEdit",
    components: {
        UsersSelectableModal,
    },
    data() {
        return {
            replace_document_shared_modal: false,
            remove_document_shared_modal: false,
            remove_shared_documents: false,
            selectAllChecked: false,
            isCheckedAll: false,
            isCheckedAllInactive : false,
            document_template_shared: [],
            user_id: null,
            table_loading: true,
            selected_templates: [],
            filtered_data: [],
            filtered_data_inactive : [],
            document_template_shared_inactive : [],
            collapse: false,
            searchTerm : '',
            searchTermInactive : '',
            innerCollapse: true,
            collapse_fields : [
                {
                    key : 'name'
                }, 
                {
                    key : 'category_name'
                },
                {
                    key : 'shared_by'
                }
            ],
            active_tab : 0,
           

        }

    },
    created() {
        this.user_id = this.$route.params.id;
        this.getDocumentTemplateShared();
    },
    computed: {
        templates_field() {
            let custom_fields_with_permission = [
                {
                    key: "checkbox", label: '', _classes: 'th-fixed-width'
                },
                { key: 'name', label: 'Template Name' },
                { key: 'category_name', label: 'Category Name' },
                { key: 'shared_by', label: 'Shared By' },
            ];
            let custom_fields = [
                { key: 'name', label: 'Template Name' },
                { key: 'category_name', label: 'Category Name' },
                { key: 'shared_by', label: 'Shared By' },
            ];
            if(this.$store.getters['can']('remove-document-template-shared') || this.$store.getters['can']('replace-document-template-shared')) {
                return custom_fields_with_permission;
            } else {
                return custom_fields;
            }
        },

        action_control: function () {
            return this.selected_templates.length > 0 ? false : true;
        },

        filteredItems() {
            let filteredData =  this.document_template_shared.filter(item =>
                Object.values(item).some(value =>
                    value.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
                )
            ).sort((a, b) => a.name.localeCompare(b.name));

            this.filtered_data = filteredData;
            return filteredData;
        },
        filteredItemsInactive () {
            let filteredDataInactive =  this.document_template_shared_inactive.filter(item =>
                Object.values(item).some(value =>
                    value.toString().toLowerCase().includes(this.searchTermInactive.toLowerCase())
                )
            ).sort((a, b) => a.name.localeCompare(b.name));

            this.filtered_data_inactive = filteredDataInactive;
            return filteredDataInactive;
        }
    },
    methods: {
        changeTab : function (val) {
            this.selected_templates = [];
            this.active_tab = val;
        },
        getDocumentTemplateShared: function () {
            this.$Progress.start()
            let requestData = {
                shared_to_user_id: this.user_id
            };
            axios.post('/drs/document-template-shared/dt-list-with-parameter', requestData, { validateStatus: () => true })
                .then(response => {
                    if (response.status == 200) {
                        for (let i = 0; i < response.data.data.length; i++) {
                            let dt = response.data.data[i].document_template;
                            let dt_shared_id = response.data.data[i].id;
                            if(dt!=null) {
                                let option = {
                                id: dt_shared_id,
                                document_template_id: response.data.data[i].document_template_id,
                                name: dt.name,
                                category_name: dt.category_name,
                                description: dt.description,
                                shared_by: response.data.data[i].shared_by_user.name
                                }
                                if(dt.deleted_at!=null) {
                                    this.document_template_shared_inactive.push(option);
                                }  else {
                                    this.document_template_shared.push(option);
                                }
                            }
                        }
                        this.table_loading = false;
                        this.$Progress.finish()
                    }

                });
        },

        rowClicked: function (item, index, column, e) {
            if(this.$store.getters['can']('replace-document-template-shared') ||  this.$store.getters['can']('remove-document-template-shared')) {
                if (column != 'checkbox') {
                    let i = this.selected_templates.findIndex((templates) => templates.id === item.id);
                    if (i !== -1) {
                        this.selected_templates.splice(i, 1);
                        if (this.selected_templates.length < 1) {
                            this.isCheckedAll = false;
                            this.resetCollapse();
                        }
                    } else {
                        this.selected_templates.push(item);
                        this.collapse = true;
                    }
                }
            }
        },


        rowClickedInactive: function (item, index, column, e) {
            if(this.$store.getters['can']('remove-document-template-shared')) {
                if (column != 'checkbox') {
                    let i = this.selected_templates.findIndex((templates) => templates.id === item.id);
                    if (i !== -1) {
                        this.selected_templates.splice(i, 1);
                        if (this.selected_templates.length < 1) {
                            this.isCheckedAllInactive = false;
                            this.resetCollapse();
                        }
                    } else {
                        this.selected_templates.push(item);
                        this.collapse = true;
                    }
                }
            }
        },

        selectChange() {
            if (!this.isCheckedAll) {
                this.selected_templates = [];
                this.collapse = false;
            } else {
                if(this.searchTerm != '') {
                    if(this.selected_templates.length > 0) {
                        for(let i = 0; i < this.filtered_data.length; i++) {
                            this.selected_templates.push(this.filtered_data[i]);
                        }
                        
                    } else {
                        this.selected_templates = this.filtered_data.map(item => item);
                    }
                } else {
                    this.selected_templates = this.document_template_shared.map(item => item);
                }
                if(this.document_template_shared.length>0) {
                    this.collapse = true;
                }
            }
        },
        selectChangeInactive() {
            if (!this.isCheckedAllInactive) {
                this.selected_templates = [];
                this.collapse = false;
            } else {
                if(this.searchTermInactive != '') {
                    if(this.selected_templates.length > 0) {
                        for(let i = 0; i < this.filtered_data_inactive.length; i++) {
                            this.selected_templates.push(this.filtered_data_inactive[i]);
                        }
                        
                    } else {
                        this.selected_templates = this.filtered_data_inactive.map(item => item);
                    }
                } else {
                    this.selected_templates = this.document_template_shared_inactive.map(item => item);
                }
                if(this.document_template_shared_inactive.length>0) {
                    this.collapse = true;
                }
            }
        },
        removeUser: function () {
            let userName = this.$route.query.name;
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html : this.active_tab==0 ? `You are trying to <b>remove</b> ${userName} from the selected document template(s).` : `The selected document template is currently inactive, would you still like to <b>remove</b> ${userName} from the document template(s)`, 
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => {
                    this.$Progress.start()
                    let selectedData = [];
                    if(this.selected_templates.length > 0) {
                        for (let i = 0; i < this.selected_templates.length; i++) {
                            let filteredData = this.document_template_shared.filter((x) => x.id == this.selected_templates[i].id);
                            let filteredDataInactive = this.document_template_shared_inactive.filter((x) => x.id == this.selected_templates[i].id);
                            let data = {
                                id: this.paramEncoder(this.selected_templates[i].id),
                                document_template_id: this.active_tab == 0 ? this.paramEncoder(filteredData[0].document_template_id) : this.paramEncoder(filteredDataInactive[0].document_template_id)
                            };
                            selectedData.push(data);

                        }
                    }
                    return axios.post('/drs/document-template-shared/remove', selectedData, { validateStatus: () => true })
                        .then(response => {
                            if (response.status == 200) {
                                this.$swal({
                                    toast: true,
                                    position: 'top-right',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    icon: 'success',
                                    title: `User(s) removed successfully!`,
                                    showConfirmButton: false,
                                    timerProgressBar: true,
                                })
                                this.isCheckedAll = false;
                                this.isCheckedAllInactive = false;
                                this.selected_templates = [];
                                this.filtered_data = [],
                                this.filtered_data_inactive = [];
                                this.document_template_shared = [];
                                this.document_template_shared_inactive = [];
                                this.resetCollapse();
                                this.getDocumentTemplateShared();
                                this.table_loading = false;
                                this.$Progress.finish()
                            }
                        });

                }
            }).then((result) => {
                if (!result.value) {
                    this.$swal('Cancelled!', '', 'error');
                }
            })
            return;
        },

        resetCollapse: function () {
            this.collapse = false;
            /* this.innerCollapse = false; */
        },

        showUserSelectableModal: function () {
            let selected_id = [];
            let decoded_uri=  decodeURIComponent(this.user_id);
            let decodedId = atob(decoded_uri);
            selected_id.push(parseInt(decodedId));
            this.$refs.usersSelectableModal.users_selectable_modal = true,
                this.$emit('show_users_selectable_modal', selected_id, true);

        },

        userMultipleSelected: function (object) {
            let _data = {
                document_template_id: [],
                share: [],
                current_user: this.user_id
            }

            if (object.length > 0) {
                for (let i = 0; i < object.length; i++) {
                    let _row = {
                        shared_by_user_id: this.paramEncoder(this.$store.getters['getUserDetails'].id),
                        shared_to_user_id: this.paramEncoder(object[i]),
                    }
                    _data.share.push(_row)
                }

                for (let j = 0; j < this.selected_templates.length; j++) {
                    _data.document_template_id.push(this.paramEncoder(this.selected_templates[j].document_template_id));
                }
            }
            this.$Progress.start()
            return axios.post('/drs/document-template-shared/replace', _data, { validateStatus: () => true }).then(response => {
                if (response.status == 200) {
                    this.$emit('show_users_selectable_modal_closed');
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `User(s) replaced successfully!`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })

                    // this.users_shared_routing_list.push(_row)

                    this.isCheckedAll = false;
                    this.selected_templates = [];
                    this.searchTerm = ''
                    this.filtered_data = [];
                    this.filtered_data_inactive = [];
                    this.document_template_shared = [];
                    this.document_template_shared_inactive = [];
                    this.getDocumentTemplateShared();
                    this.resetCollapse();
                    this.table_loading = false;
                    this.$Progress.finish()

                }
            })
        }

    },
    watch: {
        selected_templates : function (value) {
            if(value) {
                this.collapse = true;
                if(this.selected_templates.length < 1) {
                    this.isCheckedAll = false;
                    this.isCheckedAllInactive = false;
                    this.collapse=false;
                }
            }
        },
    },

}
</script>